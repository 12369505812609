import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {Descriptions, Table, Tabs, Button, Alert} from 'antd';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import {showAdminLiveStreamCalcModal} from '../../Components/AdminLiveStreamCalcModal';
import AdminLineDivider from '../../Components/AdminLineDivider';
import AdminProductBasicInfo from '../../Generators/AdminResource/AdminProductBasicInfo';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import {WORK_AROUND_PRODUCTS} from '../../Utils/ProductUtil';
import {
  generateExportRecords,
  generateExportRecordsToAoa,
  generateExportWorkSheet,
  generateExcelFile,
} from '../../Utils/LiveStreamExportUtil';

function LiveStreamProcess(props) {
  const params = qs.parse(props.location.search);
  const [staffs] = useOutlet('staffs');
  const [products] = useOutlet('products');

  const [records, setRecords] = React.useState(null);
  const [signRecords, setSignRecords] = React.useState(null);
  const [viewtimeRecords, setViewtimeRecords] = React.useState(null);

  const [downloadUrl, setDownloadUrl] = React.useState(null);

  const targetProduct = products.find((p) => p.id === params.product);

  React.useEffect(() => {
    async function fetchRecordsByProductId(id) {
      try {
        AppActions.setLoading(true);
        const resp = await JStorage.fetchDocuments(
          'live_stream_history',
          {
            product_id: id,
          },
          ['-created'],
          {offset: 0, limit: 100},
        );

        if (resp.total > 0) {
          const nextRecords = [...resp.results];

          for (let record of nextRecords) {
            const hasViewHistories =
              Array.isArray(record.view_histories) &&
              record.view_histories.length > 0;

            let on_stream_time = Number(record.on_time);
            let off_stream_time = Number(record.off_time);

            if (hasViewHistories) {
              let view_histories = record.view_histories;
              let users_view_histories = [];

              let users_backup = {};
              let users_time = {};
              let user_valid_data = {};

              for (let history of view_histories) {
                let user_id = history.user_id;

                if (staffs.findIndex((s) => s.id === user_id) > -1) {
                  continue;
                }

                let status = history.status;
                let status_time = Number(history.timestamp);
                let user_time = users_time[history.user_id] || 0;

                if (
                  status_time >= on_stream_time &&
                  status_time <= off_stream_time
                ) {
                  let temp = user_valid_data[user_id] || [];
                  temp.push({status, time: status_time});
                  user_valid_data[user_id] = temp;
                }
              }

              for (let user_id in user_valid_data) {
                let user_backup = [];
                let user_time = users_time[user_id] || 0;
                let user_pre_state = '';
                let user_pre_time = 0;

                let status_list = user_valid_data[user_id];

                for (let status_data of status_list) {
                  if (
                    status_list.indexOf(status_data) === 0 &&
                    status_data['status'] === 'disconnected'
                  ) {
                    user_time += Number(status_data['time']) - on_stream_time;
                  } else if (
                    status_data['status'] === 'connected' &&
                    user_pre_state === ''
                  ) {
                    user_pre_state = 'connected';
                    user_pre_time = status_data['time'];
                  } else if (
                    user_pre_state === 'connected' &&
                    status_data['status'] === 'disconnected'
                  ) {
                    user_time +=
                      Number(status_data['time']) - Number(user_pre_time);
                    user_pre_state = '';
                    user_pre_time = 0;
                  }

                  user_backup.push(status_data);
                }

                if (user_pre_state === 'connected') {
                  user_time += off_stream_time - Number(user_pre_time);
                }

                users_time[user_id] = user_time;
                users_backup[user_id] = user_backup;
              }

              const user_ids = Object.keys(users_time);
              const users = await JStorage.fetchAllDocuments(
                'user_profile',
                {owner: {$in: user_ids}},
                null,
                {owner: 1, state: 1, substate: 1, id_card_number: 1, name: 1},
              );

              for (let i = 0; i < user_ids.length; i++) {
                const targetUser = users.find((u) => u.owner === user_ids[i]);

                users_view_histories.push({
                  state: targetUser?.state,
                  substate: targetUser?.substate,
                  id_card_number: targetUser?.id_card_number,
                  name: targetUser?.name,
                  user_id: user_ids[i],
                  user_time: users_time[user_ids[i]],
                  user_backup: users_backup[user_ids[i]],
                });
              }

              if (WORK_AROUND_PRODUCTS.includes(id)) {
                const total = off_stream_time - on_stream_time;
                const standard = 135 * 60 * 1000;

                record['user_view_histories'] = users_view_histories
                  .sort((a, b) =>
                    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
                  )
                  .map((r, i) => {
                    const nextUserTime =
                      r.user_time * 1.85 >= total
                        ? standard + r.user_time * 0.25 >= total
                          ? total
                          : standard + r.user_time * 0.25
                        : r.user_time * 1.85;
                    return {...r, user_time: nextUserTime};
                  });
              } else {
                record[
                  'user_view_histories'
                ] = users_view_histories.sort((a, b) =>
                  (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
                );
              }
            } else {
              record['user_view_histories'] = [];
            }

            record['on_stream_time'] = on_stream_time;
            record['off_stream_time'] = off_stream_time;

            record['start_time_display'] = on_stream_time
              ? new Date(on_stream_time).toLocaleString('sv')
              : '';

            record['end_time_display'] = off_stream_time
              ? new Date(off_stream_time).toLocaleString('sv')
              : '';
          }

          setRecords(nextRecords);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    async function fetchSignRecordsByProductId(id) {
      try {
        AppActions.setLoading(true);

        const resp = await JStorage.fetchAllDocuments('sign_record', {
          product_id: id,
        });

        if (resp.length > 0) {
          const histories = await JStorage.fetchAllDocuments('sign_history', {
            sign_id: {$in: resp.map((r) => r.id)},
          });
          const users = await JStorage.fetchAllDocuments('user_profile', {
            owner: {$in: histories.map((h) => h.owner)},
          });

          const nextRecords = [...resp].map((r) => {
            const targetHistories = histories
              .filter((h) => h.sign_id === r.id)
              .map((h) => {
                const targetUser = users.find((u) => u.owner === h.owner);

                return {
                  ...h,
                  state: targetUser?.state,
                  substate: targetUser?.substate,
                  id_card_number: targetUser?.id_card_number,
                  name: targetUser?.name,
                };
              });

            const start_time_display = r.start_time
              ? new Date(r.start_time).toLocaleString('sv')
              : '';
            const end_time_display = r.end_time
              ? new Date(r.end_time).toLocaleString('sv')
              : '';

            return {
              ...r,
              start_time_display,
              end_time_display,
              users_sign_histories: targetHistories,
            };
          });

          setSignRecords(nextRecords);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    async function fetchViewtimeRecordsByProductId(id) {
      try {
        AppActions.setLoading(true);

        const nextRecords = await JStorage.fetchAllDocuments(
          'live_stream_viewtime',
          {product_id: id},
        );

        if (nextRecords.length > 0) {
          const user_ids = nextRecords.map((r) => r.user_id);
          const users = await JStorage.fetchAllDocuments(
            'user_profile',
            {
              owner: {$in: user_ids},
            },
            null,
            {owner: 1, state: 1, substate: 1, id_card_number: 1, name: 1},
          );

          setViewtimeRecords(
            nextRecords.map((r) => {
              const targetUser = users.find((user) => user.owner === r.user_id);

              if (targetUser) {
                return {
                  ...r,
                  state: targetUser.state,
                  substate: targetUser.substate,
                  id_card_number: targetUser.id_card_number,
                  name: targetUser.name,
                };
              }

              return {...r};
            }),
          );
        }
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (params.product) {
      fetchRecordsByProductId(params.product);
      fetchSignRecordsByProductId(params.product);
      fetchViewtimeRecordsByProductId(params.product);
    }
  }, [params.product, staffs]);

  return (
    <Wrapper>
      <div className="title-container">
        <BackButton
          onClick={() =>
            AppActions.navigate(
              `/admin/sign_forms/?action=detail&id=${params.signform}`,
            )
          }>
          <ChevronLeft size={24} />
          <div className="back-btn-text">返回</div>
        </BackButton>

        <h1>直播紀錄</h1>
      </div>

      <AdminLineDivider title="系統操作" />
      <Button
        style={{marginRight: 15}}
        onClick={() => {
          showAdminLiveStreamCalcModal({
            records: records || [],
            signRecords: signRecords || [],
            viewtimeRecords: viewtimeRecords || [],
            product: targetProduct,
            signFormId: params.signform,
          });
        }}>
        試算線上出席
      </Button>

      <Button
        onClick={async () => {
          const nextRecords = generateExportRecords({
            records: records || [],
            signRecords: signRecords || [],
            viewtimeRecords: viewtimeRecords || [],
          });

          const nextRecordsToAoa = generateExportRecordsToAoa({
            product: targetProduct,
            nextRecords,
            recordIds: (records || []).map((r) => r.id),
            recordTitles: (records || []).map(
              (r) => `連線\n${r.start_time_display}\n${r.end_time_display}`,
            ),
            signRecordIds: (signRecords || []).map((r) => r.id),
            signRecordTitles: (signRecords || []).map(
              (sr) => `點名\n${sr.start_time_display}\n${sr.end_time_display}`,
            ),
            viewtimeRecordTitles: ['觀看開始', '觀看時長'],
          });

          const link = await generateExcelFile(
            nextRecordsToAoa,
            generateExportWorkSheet,
          );

          if (link) {
            setDownloadUrl(link);
          }
        }}>
        下載直播紀錄
      </Button>
      {downloadUrl && (
        <Button
          type="link"
          href={downloadUrl}
          target="_blank"
          download={`${
            targetProduct.name
          }直播紀錄_${new Date()
            .toLocaleDateString('sv')
            .replace(/-/g, '')}.xlsx`}>
          下載報表
        </Button>
      )}
      <AdminLineDivider title="直播紀錄資料" />
      {targetProduct && (
        <div className="content-container">
          <AdminProductBasicInfo context={{instance: targetProduct}} />
        </div>
      )}

      <Tabs>
        <Tabs.TabPane
          tab={`直播觀看紀錄 (${
            viewtimeRecords ? viewtimeRecords.length : 0
          })`}>
          {viewtimeRecords && (
            <div className="content-container">
              <Table
                style={{marginBottom: 10}}
                pagination={{
                  pageSize: 50,
                  size: 'small',
                  showTotal: (total) => `共 ${total} 筆`,
                }}
                dataSource={viewtimeRecords}
                columns={[
                  {
                    title: '狀態',
                    key: 'state',
                    dataIndex: 'state',
                    render: (_, r) => getUserStateLabel(r.state),
                  },
                  {
                    title: '次狀態',
                    key: 'substate',
                    dataIndex: 'substate',
                    render: (_, r) => getUserSubstateLabel(r.substate),
                  },
                  {
                    title: '身分證字號',
                    key: 'id_card_number',
                    dataIndex: 'id_card_number',
                  },
                  {
                    title: '姓名',
                    key: 'name',
                    dataIndex: 'name',
                  },
                  {
                    title: '開始觀看時間',
                    key: 'start',
                    dataIndex: 'start',
                    render: (_, r) => {
                      return r.start
                        ? new Date(r.start).toLocaleString('sv')
                        : '---';
                    },
                  },
                  {
                    title: '觀看時長',
                    key: 'played',
                    dataIndex: 'played',
                    render: (_, r) => {
                      const minutes = parseInt(r.played / 1000 / 60);
                      const seconds = parseInt((r.played % (1000 * 60)) / 1000);

                      return (
                        <div>
                          {minutes} 分 {seconds} 秒
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          )}
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={`直播連線紀錄（${records ? records.length : 0})`}
          key="2">
          {targetProduct && WORK_AROUND_PRODUCTS.includes(targetProduct.id) && (
            <Alert
              showIcon
              type="warning"
              message="請注意：下列直播連線時長，皆採用加權計算。"
              style={{marginBottom: 10}}
            />
          )}
          {records &&
            records.map((record) => {
              return (
                <div className="content-container">
                  <Descriptions bordered style={{marginBottom: 10}}>
                    <Descriptions.Item>
                      直播開啟：
                      <div>{record.start_time_display}</div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      直播結束：
                      <div>{record.end_time_display}</div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      總時長：
                      {(() => {
                        const total =
                          record.off_stream_time - record.on_stream_time;
                        const minutes = parseInt(total / 1000 / 60);
                        const seconds = parseInt((total % (1000 * 60)) / 1000);

                        return (
                          <div>
                            {minutes} 分 {seconds} 秒
                          </div>
                        );
                      })()}
                    </Descriptions.Item>
                  </Descriptions>
                  <Table
                    style={{marginBottom: 10}}
                    pagination={{
                      pageSize: 50,
                      size: 'small',
                      showTotal: (total) => `共 ${total} 筆`,
                    }}
                    dataSource={record.user_view_histories}
                    columns={[
                      {
                        title: '狀態',
                        key: 'state',
                        dataIndex: 'state',
                        render: (_, r) => getUserStateLabel(r.state),
                      },
                      {
                        title: '次狀態',
                        key: 'substate',
                        dataIndex: 'substate',
                        render: (_, r) => getUserSubstateLabel(r.substate),
                      },
                      {
                        title: '身分證字號',
                        key: 'id_card_number',
                        dataIndex: 'id_card_number',
                      },
                      {
                        title: '姓名',
                        key: 'name',
                        dataIndex: 'name',
                      },
                      {
                        title: '開始連線時間',
                        key: 'user_backup',
                        dataIndex: 'user_backup',
                        render: (_, r) => {
                          return r.user_backup
                            .sort((a, b) => a.time - b.time)
                            .filter((item) => item.status === 'connected')
                            .filter((_, i) => i === 0)
                            .map((item) => (
                              <div>
                                {new Date(item.time).toLocaleString('sv')}
                              </div>
                            ));
                        },
                      },
                      {
                        title: '連線時長',
                        key: 'user_time',
                        dataIndex: 'user_time',
                        render: (_, r) => {
                          const minutes = parseInt(r.user_time / 1000 / 60);
                          const seconds = parseInt(
                            (r.user_time % (1000 * 60)) / 1000,
                          );

                          return (
                            <div>
                              {minutes} 分 {seconds} 秒
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            })}
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={`直播點名紀錄（${signRecords ? signRecords.length : 0}）`}
          key="3">
          {signRecords &&
            signRecords.map((record) => {
              return (
                <div className="content-container">
                  <Descriptions bordered style={{marginBottom: 10}}>
                    <Descriptions.Item>
                      點名開啟：
                      {record.start_time_display}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      點名結束：
                      {record.end_time_display}
                    </Descriptions.Item>
                  </Descriptions>
                  <Table
                    style={{marginBottom: 10}}
                    pagination={{
                      pageSize: 50,
                      size: 'small',
                      showTotal: (total) => `共 ${total} 筆`,
                    }}
                    dataSource={record.users_sign_histories}
                    columns={[
                      {
                        title: '狀態',
                        key: 'state',
                        dataIndex: 'state',
                        render: (_, r) => getUserStateLabel(r.state),
                      },
                      {
                        title: '次狀態',
                        key: 'substate',
                        dataIndex: 'substate',
                        render: (_, r) => getUserSubstateLabel(r.substate),
                      },
                      {
                        title: '身分證字號',
                        key: 'id_card_number',
                        dataIndex: 'id_card_number',
                      },
                      {
                        title: '姓名',
                        key: 'name',
                        dataIndex: 'name',
                      },
                      {
                        title: '簽到時間',
                        key: 'created',
                        dataIndex: 'created',
                        render: (_, r) => {
                          return (
                            <div>
                              {new Date(r.created).toLocaleString('sv')}
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            })}
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  width: 100%;

  & > .title-container {
    display: flex;

    & > h1 {
      font-size: 32px;
      margin-left: 10px;
    }
  }

  & > .content-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;

export default withPageEntry(LiveStreamProcess);
